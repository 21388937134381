import React, { useState, } from "react";
import { Link } from "react-router-dom";
import '../../Styling/Components.css';
import '../../Styling/Podcast.css';
import Footer from '../Footer';
import AnActorsLifeforMe from "../../Styling/Assets/Podcast/AnActorLifeforMe.jpg"
import GirlDadsRule from "../../Styling/Assets/Podcast/GirlDadsRule.jpg"
import ChoosingJoy from "../../Styling/Assets/Podcast/ChoosingJoy.png"
import HealthyDisruption from "../../Styling/Assets/Podcast/HealthyDisruption.jpg"
import HijackingHistory from "../../Styling/Assets/Podcast/HijackingHistory1776theMusical.jpg"
import IDontSeeColor from "../../Styling/Assets/Podcast/IDontSeeColor.png"
import IntoTheWoods from "../../Styling/Assets/Podcast/IntoTheWoods.jpg"
import LosingOurReligion from "../../Styling/Assets/Podcast/LosingOurReligion.jpg"
import PersonalSocrates from "../../Styling/Assets/Podcast/PersonalSocrates.jpg"
import QueerAgain from "../../Styling/Assets/Podcast/QueerAgain.jpg"
import QueerInColor from "../../Styling/Assets/Podcast/QueerInColor.png"
import TheDevilWearsPradatheMusical from "../../Styling/Assets/Podcast/TheDevilWearsPradatheMusical.jpg"
import ThereAreNoNewStories from "../../Styling/Assets/Podcast/ThereAreNoNewStories.jpg"
import UnpackingNewThoughtMetaphysics from "../../Styling/Assets/Podcast/UnpackingNewThoughtMetaphysics.jpg"
import LivingAnEvidenceBasedLife from '../../Styling/Assets/Podcast/LivingAnEvidenceBasedLife.png'
import OvercomingOverload from "../../Styling/Assets/Podcast/OvercomingOverload.png"
import WhatsWrongWithDEI from "../../Styling/Assets/Podcast/WhatsWrongWithDEI.png" 


const episodeLinks = [
    { 
        imageUrl: PersonalSocrates, 
        iframelink: "https://open.spotify.com/embed/episode/6OqvdEqILPX2gpCmDuxiV8?utm_source=generator",
        link: "https://open.spotify.com/episode/6OqvdEqILPX2gpCmDuxiV8",
        episodeName:"Personal Socrates: Asking The Questions That Hurt with Marc Champagne",
        description: "This episode is all about questions, what we ask ourselves, what we ask of ourselves and specifically how we challenge ourselves into further evolution. Bryan is joined by serial entrepreneur and mental fitness strategist Marc Champagne who is the author of the best-selling book, Personal Socrates: Better Questions, Better Life."
    },
    { 
        imageUrl: QueerInColor, 
        iframelink: "https://open.spotify.com/embed/episode/1qdhF8O2pX78tGvQ8Rujxa?utm_source=generator",
        link: "https://open.spotify.com/episode/1qdhF8O2pX78tGvQ8Rujxa?si=IDB-K_AgStGGgn_psj7UJw",
        episodeName:"Queer In Color • HIV Allyship with Paris Mullen",
        description: "Exploring the varying experiences of black queer men through the lens of professional health advocate and HIV activist, Paris Mullen. In this conversation we talk through Paris’ journey to becoming a fully realized queer man, how he has found community within the queer community, and how his diagnosis led him on a path to advocacy and promoting HIV allyship."
    },
    { 
        imageUrl: IDontSeeColor,
        iframelink: "https://open.spotify.com/embed/episode/6y9MoDnAeTcm9m26YQNoCv?utm_source=generator",
        link: "https://open.spotify.com/episode/6y9MoDnAeTcm9m26YQNoCv?si=xxmdowtCTAqm_Cnxsl7Qug",
        episodeName:"I Don't See Color: Race & Affirmative Action with Ameerah McBride, Esq.",
        description: "Bryan hosts a conversation about the modern evolution of social justice and the real life application of affirmative action with transformational inclusion advocate and DEI expert, Ameerah McBride, Esq. Ameerah McBride currently serves as the inaugural Chief Diversity Officer at The Association of Universities for Research in Astronomy (AURA). Prior to joining AURA, Ameerah was Chief Diversity Officer, Director of Equity and Inclusion and Title IX Coordinator at Texas State University"
    },
    { 
        imageUrl: IntoTheWoods, 
        iframelink: "https://open.spotify.com/embed/episode/6R0LVFEpcUib7vnsGhWfAD?utm_source=generator",
        link: "https://open.spotify.com/episode/6R0LVFEpcUib7vnsGhWfAD?si=KL7hMTEDQyO2Cvx5UTjg4A",
        episodeName:"Into the Woods with Montego Glover",
        description: "Bryan hosts Tony Nominated Actress Montego Glover to discuss her experience in the ongoing Broadway Revival of Stephen Sondheim's Into the Woods."
    },
    { 
        imageUrl: LosingOurReligion, 
        iframelink: "https://open.spotify.com/embed/episode/7a1WYcrqzrXD5Q9ZtD2w3F?utm_source=generator",
        link: "https://open.spotify.com/episode/7a1WYcrqzrXD5Q9ZtD2w3F?si=FytRt9h6R--96sR9xuj3Wg",
        episodeName:"Losing Our Religion: Christian Supremacy & Conscious Spirituality with Reverend Lola Wright",
        description: "What is the role of religion in society? With each successive generation becoming less and less religiously affiliated, it’s hard to tell. Bryan hosts spiritual leader Reverend Lola Wright to discuss her journey of faith, the contrast of spirituality and religiosity, and her perspective on how to create positive social impact."
    },
    { 
        imageUrl: ThereAreNoNewStories, 
        iframelink: "https://open.spotify.com/embed/episode/2IxxmibDC2xGyM2yWQENFN?utm_source=generator",
        link: "https://open.spotify.com/episode/2IxxmibDC2xGyM2yWQENFN?si=D359OO0xT-yaQw0fd3JuWA",
        episodeName:"There Are No New Stories: Acting, Archetypes, & House of the Dragon with Jane Drake Brody",
        description: "Atoms do not make up the fabric of our universe, stories do. Shared stories are the common thread between every religion, culture, nation, and community. Bryan hosts actress, master acting coach, author and former casting director Jane Drake Brody (HBO’s Somebody Somewhere) to discuss the historic roots of storytelling, the archetypes we all continue to embody, and how all of this knowledge relates in a modern context."
    },
    { 
        imageUrl: AnActorsLifeforMe, 
        iframelink: "https://open.spotify.com/embed/episode/0PmqknZnCnZUpSISnntjnP?utm_source=generator",
        link: "https://open.spotify.com/episode/0PmqknZnCnZUpSISnntjnP?si=vt4MRhNwS8WLfDaTygmSXg",
        episodeName:"An Actor's Life For Me with Adria Dawn",
        description: "Bryan hosts Emmy nominated multi-hyphenate, Adria Dawn to discuss the day to day life of working artists, the ongoing SAG-AFTRA strike, and the social impact of progressive creative development."
    },
    { 
        imageUrl: GirlDadsRule, 
        iframelink: "https://open.spotify.com/embed/episode/3j88jLl1ZLp2iTGiV3wYIq?utm_source=generator",
        link: "https://open.spotify.com/episode/3j88jLl1ZLp2iTGiV3wYIq?si=SfFvwMMCQOGRpNFR3cWkZw",
        episodeName:"Girl-Dads Rule | Political Alignment, The Gay Agenda, & Black Mermaids with Conner Moore",
        description: "Bryan is joined by social and political commentator Conner Moore, Co-Founder of Soulfire Productions and Host of the Conner Wanders Podcast to discuss his journey of fatherhood, how he defines his political alignment , his journey as an ally of the queer community, and his take on diversification in hollywood casting."
    },
    { 
        imageUrl: WhatsWrongWithDEI, 
        iframelink: "https://open.spotify.com/embed/episode/0HGeHHYSKFWN5eqTbB3Fo8?utm_source=generator",
        link: "https://open.spotify.com/episode/0HGeHHYSKFWN5eqTbB3Fo8?si=gwjc5qEVRqW2YRBiSUkG4A",
        episodeName:"Higher Education, Social Mobility, and What's Wrong with DEI with Timber Smith",
        description: "Higher education, its role in promoting social mobility, and a deconstruction of modern diversity, equity and inclusion from a career practitioner. Timber Smith, Diversity, Equity, and Inclusion (DEI) Coordinator for the City of Appleton, Special Assistant to the Mayor of Appleton, and Producer and Co-host for the City of Appleton’s Podcast, Appleton Engaged and THE KOSH Podcast."
    },
    { 
        imageUrl: HealthyDisruption, 
        iframelink: "https://open.spotify.com/embed/episode/1m0fwZyJqV5Hafl8Orj35T?utm_source=generator",
        link: "https://open.spotify.com/episode/1m0fwZyJqV5Hafl8Orj35T?si=DrVhGMgLRxSKpTGNAQyziw",
        episodeName:"Healthy Disruption: The Benefit & Burden of a Black Healthcare Executive with Gyasi Chisley",
        description: "Bryan hosts Healthcare Executive, Gyasi Chisley the author of “Healthy Disruption: The Benefit and Burden of a Black Healthcare Executive in America”. In this conversation we discuss Gyasi’s experience rising through the ranks of the healthcare industry, his philosophy on holistic health & community engagement, as well as his unique perspective on the future of healthcare in America."
    },
    { 
        imageUrl: ChoosingJoy, 
        iframelink: "https://open.spotify.com/embed/episode/4J07DyhPnQflWoWGxIseGs?utm_source=generator",
        link: "https://open.spotify.com/episode/4J07DyhPnQflWoWGxIseGs?si=3EgzJ8GdTwmmSUkzNxKrog",
        episodeName:"Choosing Joy • Cultivating Impact on Chicago's South Side with Derrick Fleming Jr.",
        description: "Bryan is in conversation with non-profit strategist, Obama Administration alumni and national speaker Derrick Fleming Jr. They discuss Derrick's story of personal development that led him on the path to create and sustain a material impact on Chicago's South Side. Derrick is the Founder and Principal of TGEL Enterprises, a mission driven consultancy."
    },
    { 
        imageUrl: QueerAgain, 
        iframelink: "https://open.spotify.com/embed/episode/0W5vD65GLvOR3QSpUsewA8?utm_source=generator",
        link: "https://open.spotify.com/episode/0W5vD65GLvOR3QSpUsewA8",
        episodeName:"Queer Again (Visibly) with Paul Butler and Eric Wilkerson",
        description: "Bryan hosts two conversations with Paul Butler, Co-Founder of the \"I Am Here Collective\", a queer apparel brand with the mission of increasing queer visibility as well as advocate and non-profit development expert, Eric Wilkerson (Founder/Principal of Wilkerson & Co.) to dive into their queer experiences, the progression of their advocacy, and talk through the depiction of queerness in the national discourse."
    },
    { 
        imageUrl: TheDevilWearsPradatheMusical,
        iframelink: "https://open.spotify.com/embed/episode/6XCDuZcRfzrdg5jJB3yCiF?utm_source=generator",
        link: "https://open.spotify.com/episode/6XCDuZcRfzrdg5jJB3yCiF?si=AlTqVvNATYG9QMquh_bPuw",
        episodeName:"The Devil Wears Prada the Musical with Megan Masako Haley",
        description: "The Devil Wears Prada the Musical had its world premier in Chicago, Illinois at the James M. Nederlander Theatre in the summer of 2022 for a five-week limited engagement. While the show is ramping up for its London run, we are looking back to Bryan’s conversation with cast member Megan Masako Haley (Emily Charlton)."
    },
    { 
        imageUrl: UnpackingNewThoughtMetaphysics, 
        iframelink: "https://open.spotify.com/embed/episode/7a66lYgaHzxBv4Hmg4oCEl?utm_source=generator",
        link: "https://open.spotify.com/episode/7a66lYgaHzxBv4Hmg4oCEl?si=f_mgfLkNQyu40NEGS3yUJg",
        episodeName:"Losing Our Religion: Unpacking New Thought Metaphysics with Reverend Gaylon McDowell",
        description: "What is the role of religion in society? With each successive generation becoming less and less religiously affiliated, it’s hard to tell. Bryan hosts spiritual leader Reverend Gaylon McDowell to discuss his journey of faith, a comprehensive breakdown of new thought metaphysics, and his perspective on the role of faith in the modern age."
    },
    { 
        imageUrl: HijackingHistory, 
        iframelink: "https://open.spotify.com/embed/episode/0HGeHHYSKFWN5eqTbB3Fo8?utm_source=generator",
        link: "https://open.spotify.com/episode/6D1KxNwwjSUuc6o9sSJbPg?si=OgZgR60PQEKxjpbj2Gpf_w",
        episodeName:"Hijacking History with Brooke Simpson",
        description: "Bryan hosts international recording artist and actress Brooke Simpson to discuss her experience in the retelling of the American story in the direct from Broadway National Tour of 1776: The Musical. Brooke was a finalist on NBC’s The Voice and a finalist on America’s Got Talent! and has released multiple singles including her latest release, “Haliwa”."
    },
    { 
        imageUrl: LivingAnEvidenceBasedLife, 
        iframelink: "https://open.spotify.com/embed/episode/2vbaRgLphnQxT09glG8y4V?utm_source=generator",
        link: "https://open.spotify.com/episode/2vbaRgLphnQxT09glG8y4V",
        episodeName:"Living An Evidence Based Life with Allyson Byrd",
        description: "Bryan hosts serial entrepreneur, sales strategist, and positive voice alchemist Allyson Byrd to discuss how to explore the range of your capabilities, embrace the fluctuations of success, and the confidence needed to monetize your giftings."
    },
    { 
        imageUrl: OvercomingOverload, 
        iframelink: "https://open.spotify.com/embed/episode/3iiw8i1WWtErKWIZMiPkCV?utm_source=generator",
        link: "https://open.spotify.com/episode/3iiw8i1WWtErKWIZMiPkCV?si=U32J9hwTQrWNkbklDnXa1g",
        episodeName:"Discerning What Matters Most • Overcoming Overload with Dr. Mandy Lehto",
        description: "Overcoming Overload: Are the life goals of previous generations attainable? How do you forge a sustainable balance between high productivity and leveling burnout? Is your ambition serving you? Bryan hosts Forbes, CNBC, and Fast Company featured executive coach, Dr. Mandy Lehto to explore these questions."
    }
];


export default function Episodes() {
    
    const [playing, setPlaying] = useState(null);
    const [hovered, setHovered] = useState(null);

    const handlePlay = (index) => {
        setPlaying(playing === index ? null : index);
        if (playing !== index) {
            setHovered(null);
        }
    };

    const handleMouseEnter = (index) => {
        if (playing === null) {
            setHovered(index);
        }
    };

    const handleMouseLeave = () => {
        if (playing === null) {
            setHovered(null);
        }
    };

    return (
        <div className="episodes-container">
            <div className="featured-section">
                <h2 className="cards-title">BC's Corner Episode Library</h2>
                <div className="episode-button-div">
                    <Link to="/podcast" className="episode-button">
                        Back to Podcast
                    </Link>
                </div>
                <div className="episodes-grid">
                    {episodeLinks.map((episode, index) => (
                        <div 
                            key={index} 
                            className={`episode-card ${hovered === index ? 'hovered' : ''}`}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <img 
                                src={episode.imageUrl} 
                                alt={episode.episodeName} 
                                className="episode-image"
                            />
                            {hovered === index && (
                                <div className="episode-description mobile-hide">
                                    <p>{episode.description}</p>
                                </div>
                            )}
                            <div className="episode-details">
                                <div className='icons-div'>
                                    <button 
                                        className='icons nav-item nav-link' 
                                        onClick={() => handlePlay(index)} 
                                    >
                                        {playing === index ? (
                                            <ion-icon size="large" name="pause-outline"></ion-icon>
                                        ) : (
                                            <ion-icon size="large" name="play-outline"></ion-icon>
                                        )}
                                    </button>		
                                </div>
                                <h3 className="episode-title">
                                    <a 
                                        href={episode.link} 
                                        target="_blank" 
                                        rel="noreferrer"
                                        className="episode-link">
                                        {episode.episodeName}
                                    </a>
                                </h3>
                            </div>
                            {playing === index && (
                                <iframe 
                                    src={episode.iframelink} 
                                    title={episode.episodeName}
                                    width="100%" 
                                    height="80" 
                                    allowtransparency="true" 
                                    allow="encrypted-media"
                                ></iframe>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
}
import React from "react";
import '../Styling/Components.css'
import '../Styling/StageScreen.css'
import CarouselComponent from "./Helpers/Carousel";
import HeadshotCarousel from "./Helpers/HeadshotCarousel";
import Footer from './Footer'
import Resume from '../Styling/Assets/Resume.png'
import ResumePDF from '../Styling/BryanCarter-resume.pdf' 

export default function Expereince(){
    return(
        <div className="experience-conatiner">
            
            <div className="resume-headshot">
                <div className="resume-container">
                    <img
                        src={Resume}
                        width="90%"
                        height="100%"
                        alt="Resume"
                    />
                    <a href={ResumePDF} download style={{ color: 'black' }}>Download Resume</a>
                </div>
                <div className="headshot-container">
                    <HeadshotCarousel />
                </div>
            </div>
            <div className="reels-container">
                <iframe 
                    src="https://www.youtube.com/embed/lRJnZXEQXpI" 
                    title="Bryan Nicholas Carter Reel 2024" 
                    frameborder="0" 
                    width="75%"
                    height="600"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerpolicy="strict-origin-when-cross-origin" 
                    allowfullscreen>
                </iframe>
            </div>
            <div className="gallery-container">
                <CarouselComponent />
            </div>
            <div>
                <Footer></Footer>
            </div>
        </div>
    )
}
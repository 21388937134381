import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import '../../Styling/StageScreen.css'
import GalleryItem1 from '../../Styling/Assets/Gallery/IMG_5755.jpeg';
import GalleryItem2 from '../../Styling/Assets/Gallery/IMG_5789.JPG';
import GalleryItem3 from '../../Styling/Assets/Gallery/IMG_5795.JPG';
import GalleryItem4 from '../../Styling/Assets/Gallery/IMG_5796.JPG';
import GalleryItem5 from '../../Styling/Assets/Gallery/IMG_5797.JPG';
import GalleryItem6 from '../../Styling/Assets/Gallery/IMG_5787.JPG';
import GalleryItem7 from '../../Styling/Assets/Gallery/IMG_5815.jpeg';
import GalleryItem8 from '../../Styling/Assets/Gallery/IMG_0491.JPG';
import GalleryItem9 from '../../Styling/Assets/Gallery/IMG_0493.JPG';
import GalleryItem10 from '../../Styling/Assets/Gallery/IMG_0495.JPG';
import GalleryItem11 from '../../Styling/Assets/Gallery/IMG_5997.jpeg';
import GalleryItem12 from '../../Styling/Assets/Gallery/IMG_5812.JPG';
import GalleryItem13 from '../../Styling/Assets/Gallery/IMG_5813.JPG';
import GalleryItem14 from '../../Styling/Assets/Gallery/IMG_5999.JPG';
import GalleryItem15 from '../../Styling/Assets/Gallery/IMG_5998.jpeg';
import GalleryItem16 from '../../Styling/Assets/Gallery/IMG_0499.png';


const carouselItems = [
  {
    src: GalleryItem1,
    alt: 'Image 1',
  },
  {
    src: GalleryItem2,
    alt: 'Image 2',
  },
  {
    src: GalleryItem3,
    alt: 'Image 3',
  },
  {
    src: GalleryItem4,
    alt: 'Image 4',
  },
  {
    src: GalleryItem5,
    alt: 'Image 5',
  },
  {
    src: GalleryItem6,
    alt: 'Image 6',
  },
  {
    src: GalleryItem7,
    alt: 'Image 7',
  },
  {
    src: GalleryItem8,
    alt: 'Image 8',
  },
  {
    src: GalleryItem9,
    alt: 'Image 9',
  },
  {
    src: GalleryItem10,
    alt: 'Image 10',
  },
  {
    src: GalleryItem11,
    alt: 'Image 11',
  },
  {
    src: GalleryItem12,
    alt: 'Image 12',
  },
  {
    src: GalleryItem13,
    alt: 'Image 13',
  },
  {
    src: GalleryItem14,
    alt: 'Image 14',
  },
  {
    src: GalleryItem15,
    alt: 'Image 15',
  },
  {
    src: GalleryItem16,
    alt: 'Image 16',
  }
];

export default function CarouselComponent() {
  const [itemsPerSlide, setItemsPerSlide] = useState(3); // Default to 3 items per slide

  useEffect(() => {
    const updateItemsPerSlide = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 721) {
        setItemsPerSlide(1); 
      } else {
        setItemsPerSlide(3);
      }
    };

    updateItemsPerSlide();
    window.addEventListener('resize', updateItemsPerSlide);

    return () => {
      window.removeEventListener('resize', updateItemsPerSlide);
    };
  }, []);

  const groupedItems = [];
  for (let i = 0; i < carouselItems.length; i += itemsPerSlide) {
    groupedItems.push(carouselItems.slice(i, i + itemsPerSlide));
  }

  const intervalDuration = 7000; 
  const interval = carouselItems.length > itemsPerSlide ? intervalDuration : null;

  return (
    <Carousel interval={interval}>
      {groupedItems.map((group, index) => (
        <Carousel.Item key={index}>
          <div className="d-flex justify-content-between">
            {group.map((item, itemIndex) => (
              <img
                key={itemIndex}
                className="carousel-img"
                src={item.src}
                alt={item.alt}
                style={{ width: `${100 / itemsPerSlide}%`, height: '350px', margin: '5px' }}
              />
            ))}
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
import '../Styling/Components.css'

function Footer() {
  return (
    <div>
        <footer>
                <div className="footer">
                    <div className="socialIcons">
                      <div className='icons-div'>
                        <a className='icons'href="https://open.spotify.com/show/5XUaJSBG2ZgujoGpTA4yeC?si=Ttq1IdslRZKxatTSQesD6A" target="_blank" rel="noreferrer" class="nav-item nav-link"><ion-icon size="large" name="logo-facebook"></ion-icon></a>
                      </div>
                      <div className='icons-div'>
                        <a className='icons' href="https://www.instagram.com/ibryancarter?igsh=aHFtbDJzM3h1ODIy" target="_blank" rel="noreferrer" class="nav-item nav-link"><ion-icon size="large" name="logo-instagram"></ion-icon></a>
                      </div>
                      <div className='icons-div'>
                        <a className='icons' href="https://www.linkedin.com/in/bryan-carter-397196193" target="_blank" rel="noreferrer" class="nav-item nav-link"><ion-icon size="large" name="logo-linkedin"></ion-icon></a>		
                      </div>
                      <div className='icons-div'>
                        <a className='icons' href="https://www.backstage.com/tal/bryancarter/" target="_blank" rel="noreferrer" class="nav-item nav-link"><ion-icon size="large" name="share-social-outline"></ion-icon></a>		
                      </div>
                    </div>
                    <span>Copyright © 2024 EBD</span>
                </div>
            </footer>
    </div>
  );
}

export default Footer;


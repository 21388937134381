import React from "react";
import '../Styling/Components.css';
import '../Styling/Press.css';
import Footer from './Footer';
import ChicagoTribune from '../Styling/Assets/ChicagoTribune.webp';
import UWO from '../Styling/Assets/UWO.png';
import AdvanceTitan from '../Styling/Assets/AdvancedTitan.png';
import TheOshkoshNorthwestern from '../Styling/Assets/TheOshkoshNorthwestern.webp';
import TheJournalTimes from '../Styling/Assets/TheJournalTimes.jpeg';

export default function Press() {
    const articles = [
        {
            image: TheJournalTimes,
            title: "Racine native earns Kennedy Center honor",
            description: "University of Wisconsin-Oshkosh's Theatre Department has secured two national awards for last fall's performance of \"Nat Turner in Jerusalem.\" ",
            link: "https://journaltimes.com/news/local/education/racine-native-earns-kennedy-center-honor/article_bb3bc1b2-c179-5b7d-8a64-9112ef9231d3.html"
        },
        {
            image: ChicagoTribune,
            title: "Theater | Review: ‘Middle Passage’ at Lifeline Theatre is both a swashbuckling adventure and a powerful indictment of slavery",
            description: "When Charles R. Johnson wrote the novel “Middle Passage,” which won the National Book Award in 1990 for the Evanston-born writer, he immersed himself in the musings of nautical adventurers: Herman Melville, Jack London, Robert Louis Stevenson, even the mythology of Sinbad the Sailor.",
            link: "https://www.chicagotribune.com/2020/02/28/review-middle-passage-at-lifeline-theatre-is-both-a-swashbuckling-adventure-and-a-powerful-indictment-of-slavery/"
        },
        {
            image: TheOshkoshNorthwestern,
            title: "UW-Oshkosh's production of 'Nat Turner in Jerusalem' wins two awards from the Kennedy Center",
            description: "University of Wisconsin-Oshkosh's Theatre Department has secured two national awards for last fall's performance of \"Nat Turner in Jerusalem.\" ",
            link: "https://www.thenorthwestern.com/story/entertainment/theater/2020/07/03/uw-oshkoshs-play-nat-turner-jerusalem-wins-kennedy-center-awards/5355848002/"
        },
        {
            image: UWO,
            title: "UWO senior among elite group of actors to be honored by the Kennedy Center",
            description: "Before finishing his degree in May and then ramping up his theater career in Chicago, a University of Wisconsin Oshkosh senior will be honored by the John F. Kennedy Center for the Performing Arts in Washington D.C.",
            link: "https://www.uwosh.edu/today/83176/bryan-carter-university-of-wisconsin-oshkosh-senior-honored-by-kennedy-center/"
        },
        {
            image: AdvanceTitan,
            title: "‘Nat Turner’ challenges audience",
            description: "The UW Oshkosh theatre department debuted the first college performance of the 2016 one act play written by Nathan Alan Davis, “Nat Turner in Jerusalem,” last week, providing a profound and challenging experience for its audience.",
            link: "https://advancetitan.com/opinion/2019/10/10/nat-turner-challenges-audience"
        }
    ];

    return (
        <div className="press-container">
            <h1>press</h1>
            <div className="articles">
                {articles.map((article, index) => (
                    <div key={index} className="article">
                        <img src={article.image} alt={article.title} className="article-image" />
                        <div className="article-content">
                            <h2>{article.title}</h2>
                            <p>{article.description}</p>
                            <a href={article.link} target="_blank" rel="noopener noreferrer">Read Full Article</a>
                        </div>
                    </div>
                ))}
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
}

import React from "react";
import '../Styling/Components.css';
import '../Styling/Home.css';
import Footer from './Footer';
import ProfilePic from '../Styling/Assets/ProfileImg.jpg';

export default function Home() {
    return (
        <div className="profile-container">
            <div className="profile-content-container">
                <div className="profile-image-container">
                    <img
                        src={ProfilePic}
                        className="d-inline-block profile-pic"
                        alt="ProfilePicture"
                    />
                </div>
                <div className="profile-description">
                    <h1>BRYAN NICHOLAS CARTER</h1>
                    <h3>actor • producer • podcast host</h3>
                    <p>Bryan Carter, he/him is a Chicago based actor, producer and podcast host.</p>
                    <p>His most recent credits include, Dontrell, Who Kissed the Sea (Pegasus Theatre Chicago); The Salon (Black Ensemble Theatre); Champion (Lyric Opera of Chicago); Middle Passage (LifeLine Theatre); as well as appearing in season 2 of the web series, “The Life and Times of a Unicorn” produced by Adam Lorraine Productions. <a  href="/experience" style={{ color: 'black' }}>See Full Credits</a></p>
                    <p>In 2020 Carter received prominent recognition by the Kennedy Center American College Theatre Festival, being awarded the national honor for Best Actor in an Outstanding Role as Nat Turner in Nat Turner in Jerusalem (University of Wisconsin Oshkosh) directed by Merlaine Angwall.</p>
                    <p>From 2020 to 2022, Bryan worked closely with Best-Selling Author and Personal Brand Strategist, Jessica Zweig, producing several live and virtual events as well as producing and guest hosting her podcast, The SimplyBe. Podcast, now the Spiritual Hustler Podcast.</p>
                    <p>Carter is an alumnus of The School at Steppenwolf and holds his B.A. in Political Science. He is presently represented by Actors Talent Group.</p>             
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
}

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import Press from './Components/Press';
import Contact from './Components/Contact';
import Error from './Components/Error'
import Experience from './Components/StageScreen';
import Home from './Components/Home';
import NavBar from './Components/NavBar'
import Podcast from './Components/Podcast';
import Episodes from "./Components/Helpers/AllEpisodes";


function App() {
  return (
    <div className="App">
      <Router>
        <header>
          <NavBar />
        </header>
        <Routes>
          <Route exact path='/' element={<Home />}/>
          <Route path='/press' element={<Press />}/>
          <Route path='/podcast' element={<Podcast />}/>
          <Route path='/podcast/allepisodes' element={<Episodes />}/>
          <Route path='/experience' element={<Experience />}/>
          <Route path='/contact' element={<Contact />}/>
          <Route path='/error' element={<Error />}/>
          <Route path='*' element={<Navigate to='/error' />} />
        </Routes>
        {/* <footer>
          <Footer />
        </footer> */}
      </Router>
    </div>
  );
}

export default App;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import '../../Styling/Components.css';
import '../../Styling/Podcast.css';
import IDontSeeColor from "../../Styling/Assets/Podcast/IDontSeeColor.png";
import IntoTheWoods from "../../Styling/Assets/Podcast/IntoTheWoods.jpg";
import LosingOurReligion from "../../Styling/Assets/Podcast/LosingOurReligion.jpg";
import PersonalSocrates from "../../Styling/Assets/Podcast/PersonalSocrates.jpg";
import QueerInColor from "../../Styling/Assets/Podcast/QueerInColor.png";
import ThereAreNoNewStories from "../../Styling/Assets/Podcast/ThereAreNoNewStories.jpg";

const episodeLinks = [
    { 
        imageUrl: PersonalSocrates, 
        iframelink: "https://open.spotify.com/embed/episode/6OqvdEqILPX2gpCmDuxiV8?utm_source=generator",
        link: "https://open.spotify.com/episode/6OqvdEqILPX2gpCmDuxiV8",
        episodeName:"Personal Socrates: Asking The Questions That Hurt with Marc Champagne",
        description: "This episode is all about questions, what we ask ourselves, what we ask of ourselves and specifically how we challenge ourselves into further evolution. Bryan is joined by serial entrepreneur and mental fitness strategist Marc Champagne who is the author of the best-selling book, Personal Socrates: Better Questions, Better Life."
    },
    { 
        imageUrl: QueerInColor, 
        iframelink: "https://open.spotify.com/embed/episode/1qdhF8O2pX78tGvQ8Rujxa?utm_source=generator",
        link: "https://open.spotify.com/episode/1qdhF8O2pX78tGvQ8Rujxa?si=IDB-K_AgStGGgn_psj7UJw",
        episodeName:"Queer In Color • HIV Allyship with Paris Mullen",
        description: "Exploring the varying experiences of black queer men through the lens of professional health advocate and HIV activist, Paris Mullen. In this conversation we talk through Paris’ journey to becoming a fully realized queer man, how he has found community within the queer community, and how his diagnosis led him on a path to advocacy and promoting HIV allyship."
    },
    { 
        imageUrl: IDontSeeColor,
        iframelink: "https://open.spotify.com/embed/episode/6y9MoDnAeTcm9m26YQNoCv?utm_source=generator",
        link: "https://open.spotify.com/episode/6y9MoDnAeTcm9m26YQNoCv?si=xxmdowtCTAqm_Cnxsl7Qug",
        episodeName:"I Don't See Color: Race & Affirmative Action with Ameerah McBride, Esq.",
        description: "Bryan hosts a conversation about the modern evolution of social justice and the real life application of affirmative action with transformational inclusion advocate and DEI expert, Ameerah McBride, Esq. Ameerah McBride currently serves as the inaugural Chief Diversity Officer at The Association of Universities for Research in Astronomy (AURA). Prior to joining AURA, Ameerah was Chief Diversity Officer, Director of Equity and Inclusion and Title IX Coordinator at Texas State University"
    },
    { 
        imageUrl: IntoTheWoods, 
        iframelink: "https://open.spotify.com/embed/episode/6R0LVFEpcUib7vnsGhWfAD?utm_source=generator",
        link: "https://open.spotify.com/episode/6R0LVFEpcUib7vnsGhWfAD?si=KL7hMTEDQyO2Cvx5UTjg4A",
        episodeName:"Into the Woods with Montego Glover",
        description: "Bryan hosts Tony Nominated Actress Montego Glover to discuss her experience in the ongoing Broadway Revival of Stephen Sondheim's Into the Woods."
    },
    { 
        imageUrl: LosingOurReligion, 
        iframelink: "https://open.spotify.com/embed/episode/7a1WYcrqzrXD5Q9ZtD2w3F?utm_source=generator",
        link: "https://open.spotify.com/episode/7a1WYcrqzrXD5Q9ZtD2w3F?si=FytRt9h6R--96sR9xuj3Wg",
        episodeName:"Losing Our Religion: Christian Supremacy & Conscious Spirituality with Reverend Lola Wright",
        description: "What is the role of religion in society? With each successive generation becoming less and less religiously affiliated, it’s hard to tell. Bryan hosts spiritual leader Reverend Lola Wright to discuss her journey of faith, the contrast of spirituality and religiosity, and her perspective on how to create positive social impact."
    },
    { 
        imageUrl: ThereAreNoNewStories, 
        iframelink: "https://open.spotify.com/embed/episode/2IxxmibDC2xGyM2yWQENFN?utm_source=generator",
        link: "https://open.spotify.com/episode/2IxxmibDC2xGyM2yWQENFN?si=D359OO0xT-yaQw0fd3JuWA",
        episodeName:"There Are No New Stories: Acting, Archetypes, & House of the Dragon with Jane Drake Brody",
        description: "Atoms do not make up the fabric of our universe, stories do. Shared stories are the common thread between every religion, culture, nation, and community. Bryan hosts actress, master acting coach, author and former casting director Jane Drake Brody (HBO’s Somebody Somewhere) to discuss the historic roots of storytelling, the archetypes we all continue to embody, and how all of this knowledge relates in a modern context."
    }
];

export default function FeaturedEpisodes() {

    const [playing, setPlaying] = useState(null);
    const [hovered, setHovered] = useState(null);

    const handlePlay = (index) => {
        setPlaying(playing === index ? null : index);
        if (playing !== index) {
            setHovered(null);
        }
    };

    const handleMouseEnter = (index) => {
        if (playing === null) {
            setHovered(index);
        }
    };

    const handleMouseLeave = () => {
        if (playing === null) {
            setHovered(null);
        }
    };

    return (
        <div className="episodes-container">
            <div className="featured-section">
                <h2 className="cards-title">FEATURED EPISODES</h2>
                <div className="episode-button-div">
                    <Link to="/podcast/allepisodes" className="episode-button">
                        full episode library
                    </Link>
                </div>
                <div className="episodes-grid">
                    {episodeLinks.map((episode, index) => (
                        <div 
                            key={index} 
                            className={`episode-card ${hovered === index ? 'hovered' : ''}`}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <img 
                                src={episode.imageUrl} 
                                alt={episode.episodeName} 
                                className="episode-image" 
                            />
                            {hovered === index && (
                                <div className="episode-description mobile-hide">
                                    <p>{episode.description}</p>
                                </div>
                            )}
                            <div className="episode-details">
                                <div className='icons-div'>
                                    <button 
                                        className='icons nav-item nav-link' 
                                        onClick={() => handlePlay(index)} 
                                    >
                                        {playing === index ? (
                                            <ion-icon size="large" name="pause-outline"></ion-icon>
                                        ) : (
                                            <ion-icon size="large" name="play-outline"></ion-icon>
                                        )}
                                    </button>		
                                </div>
                                <h3 className="episode-title">
                                    <a 
                                        href={episode.link} 
                                        target="_blank" 
                                        rel="noreferrer"
                                        className="episode-link">
                                        {episode.episodeName}
                                    </a>
                                </h3>
                            </div>
                            {playing === index && (
                                <iframe 
                                    src={episode.iframelink} 
                                    title={episode.episodeName}
                                    width="100%" 
                                    height="80" 
                                    allowtransparency="true" 
                                    allow="encrypted-media"
                                ></iframe>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
import React from "react";
import '../Styling/Components.css';
import '../Styling/Podcast.css';
import Footer from './Footer';
import BCLogo from '../Styling/Assets/BCCorner.png';
import Spotify from "../Styling/Assets/Spotify.png";
import Apple from "../Styling/Assets/Apple.png";
import Amazon from "../Styling/Assets/Amazon.png";
import FeaturedEpisodes from './Helpers/FeaturedEpisodes';

export default function Podcast() {
    return (
        <div className="podcast-container">
            <div className="podcast-content-container">
                <div className="podcast-logo-container">
                    <div className="podcast-logo">
                        <img
                            src={BCLogo}
                            className="BCLogo"
                            alt="BC Logo"
                        />
                    </div>
                    <div className="account-links">
                        <a 
                            href="https://open.spotify.com/show/5XUaJSBG2ZgujoGpTA4yeC?si=ZnW0TkG6TsGY__4drfxCIQ" 
                            target="_blank" 
                            rel="noreferrer">
                            <img className="Links" src={Spotify} alt="Spotify Logo" />
                        </a>
                        <a 
                            href="https://podcasts.apple.com/us/podcast/bcs-corner/id1649627000" 
                            target="_blank" 
                            rel="noreferrer">
                            <img className="Links" src={Apple} alt="Apple Logo" />
                        </a>
                        <a 
                            href="https://music.amazon.com/podcasts/bcs-corner" 
                            target="_blank" 
                            rel="noreferrer">
                            <img className="Links" src={Amazon} alt="Amazon Logo" />
                        </a>
                    </div>
                </div>
                <div className="podcast-description">
                    <h5>“Creative expression is fueled by unabashed curiosity. BC’s Corner as a podcast is the intersection of arts, society, and culture through candid conversations with critical thinkers and subject matter experts.”</h5>
                    <h5> - Bryan Carter</h5>
                </div>
                <div className="podcast-episodes">
                    <FeaturedEpisodes />
                </div>
            </div>
            <Footer />
        </div>
    );
}
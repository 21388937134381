import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import '../../Styling/Components.css';
import Headshot1 from '../../Styling/Assets/Headshot1.jpg';
import Headshot2 from '../../Styling/Assets/Headshot2.jpg';
import Headshot3 from '../../Styling/Assets/Headshot3.jpg';

const carouselItems = [
  {
    src: Headshot3,
    alt: 'Formal Headshot',
  },
  {
    src: Headshot2,
    alt: 'Serious Headshot',
  },
  {
    src: Headshot1,
    alt: 'Smiling Headshot',
  }
];

export default function HeadshotCarousel() {
  return (
    <Carousel interval={null} prevIcon={null} nextIcon={null}>
      {carouselItems.map((item, index) => (
        <Carousel.Item key={index}>
          <img className="d-block carousel-img" src={item.src} alt={item.alt} />
          <Carousel.Caption className="custom-caption">
            <a href={item.link} style={{ textDecoration: 'none', color: 'inherit' }}>
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </a>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

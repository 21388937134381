import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../Styling/Components.css';

export default function NavBar() {
  const isSmallScreen = window.innerWidth < 500;

  return (
    <Navbar expand="lg" className="bg-white navbar-custom">
      <Container fluid>
        <Navbar.Brand href="/" style={{ fontSize: isSmallScreen ? '1.5rem' : '2rem' }}>BRYAN NICHOLAS CARTER</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="ms-auto my-2 my-lg-0 navbar-links" style={{ maxHeight: '100px' }} navbarScroll>
            <Nav.Link style={{ color: 'black' }} href="/experience">stage & screen</Nav.Link>
            <Nav.Link style={{ color: 'black' }} href="/podcast">podcast</Nav.Link>
            <Nav.Link style={{ color: 'black' }} href="/press">press</Nav.Link>
            <Nav.Link style={{ color: 'black' }} href="/contact">contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
